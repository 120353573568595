<script setup lang="ts">
const model = defineModel<string | number>();

const props = withDefaults(
  defineProps<{
    error?: string;
    helpText?: string;
    inputAttrs?: Record<string, any>;
    inputId?: string;
    label?: string;
    labelAttrs?: Record<string, any>;
    type?: string;
  }>(),
  {
    error: undefined,
    helpText: undefined,
    inputAttrs: undefined,
    inputId: undefined,
    label: undefined,
    labelAttrs: undefined,
    type: "text",
  }
);

const id = computed(
  () =>
    props.inputId ?? props.label?.toLowerCase().replace(/ /g, "_") ?? undefined
);
const computedId = useInputId(id);
const errorId = computed(() => `${computedId.value}-feedback`);
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="computedId"
      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      v-bind="labelAttrs"
    >
      {{ label }}
    </label>
    <input
      :id="computedId"
      v-model="model"
      :type="type"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-background-300 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      v-bind="inputAttrs"
      :aria-describedby="error ? errorId : undefined"
    />
    <small
      v-if="helpText"
      class="block font-medium tracking-wide text-gray-500 dark:text-gray-400 text-xs my-2 ml-1"
    >
      {{ helpText }}
    </small>
    <span
      v-if="error"
      :id="errorId"
      class="block font-medium tracking-wide text-red-500 text-xs my-2 ml-1"
    >
      {{ error }}
    </span>
  </div>
</template>
